import React from 'react'
import './AboutUs.scss'
import Contact from './Contact'
import CommonFooter from './CommonFooter'

export default function AboutUs() {
  return (
    <>
    <div className='about-us-main-container'>

      {/* About us page contents1  */}
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
            <div className="wf-wrap">
                <div className="page-title-head hgroup"> 
                    <h2>About us</h2>
                </div>
                 {/* <div className="page-title-breadcrumbs">
                    <div className="assistive-text">You are here:</div>
                    <ol className="breadcrumbs text-small" xmlns:v="http://rdf.data-vocabulary.org/#">
                        <li typeof="v:Breadcrumb"><a rel="v:url" property="v:title" href="https://the7.io/dental/" title="">Home</a></li>
                        <li className="current">About us</li>
                    </ol>
                </div>  */}
            </div>
        </div>


         {/* About us page contents2  */}

        <div id="main" className="sidebar-none sidebar-divider-off" style={{paddingTop:"0px", paddingBottom:"0px", marginTop: "20px", color:"#333"}}>
          <div className="main-gradient"></div>
          <div className="wf-wrap">
            <div className="wf-container-main">
              <div id="content" className="content" role="main">
                <div id="about" className="vc_row wpb_row vc_row-fluid vc_custom_1515424261504 vc_row-o-content-middle vc_row-flex">
                  
                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                    <div className="vc_column-inner vc_custom_1515424178786">
                      <div className="wpb_wrapper">
                        <div id="ultimate-heading-4425b474a5f32621" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-4425b474a5f32621 uvc-4956 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                          <div className="uvc-heading-spacer no_spacer"></div>
                          <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-4425b474a5f32621 h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:30px;&quot;,&quot;line-height&quot;:&quot;desktop:40px;&quot;}">
                            <h3 style={{fontWeight:"bold", marginBottom:"20px"}}><p></p><p></p>We are <strong className='heading-second'>Fin</strong>Cetus Solutions.</h3>
                          </div>
                          <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target=".uvc-heading.ultimate-heading-4425b474a5f32621 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;desktop:26px;&quot;}" style={{fontWeight:"normal",marginBottom:"20px"}}><p><strong className='heading-second'>Fin</strong> <strong className='name-secondpart' style={{marginLeft:"-6px"}}> Cetus Solutions </strong>  helps individuals, business owners and investors worldwide advance their financial interests in challenging situations.</p>
                            <p></p>
                            <p> We want each customer to fully meet current and ongoing financial obligations, feel secure in their financial future, avoid financial stress, and make choices to enjoy life. </p>
                            <p></p>
                            <p> <strong>Our Objectives: </strong></p>
                            <p> 
                              <ol className='our-objective-list'>
                                <li> <strong>Economic Objective: </strong> To build wealth to achieve their life and financial goals.</li>
                                <li> <strong>Social Objective: </strong> Create value and achieve financial freedom for individuals, business owners, and investors.</li>
                                <li> <strong>Human Objective: </strong> Achieve eight Dimensions of Wellness - Physical, Spiritual, Emotional, Occupational, Intellectual, Environmental, Financial and Social.</li>
                                <li> <strong>National Objective: </strong> Contribute to the country's economy (increase national income and investments). </li>
                                <li> <strong>Global Objective: </strong> Higher Standard of living.</li>
                              </ol>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-0 vc_col-md-6 vc_col-sm-offset-2">
                    <div className="vc_column-inner vc_custom_1515424193637">
                      <div className="wpb_wrapper">
                        <div className="shortcode-single-image-wrap alignnone" style={{marginTop:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px", width:"1000px"}}>
                          <div className="shortcode-single-image">
                            <div className="fancy-media-wrap"><img className="lazy-load preload-me is-loaded" src={require("../images/aboutus.jpg")}  width="1000" height="700" data-dt-location="images/" style={{willChange: "auto"}} alt="" cust-srcset="images/about-us.jpg 1000w, images/about-us.jpg 1142w" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                {/* Why fincetus heading */}
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0" style={{marginTop: "50px"}}>
                  <div className="vc_column-inner vc_custom_1515176114270_1 ">
                    <div className="wpb_wrapper">
                      <div id="ultimate-heading-72065b463bf142a26" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-72065b463bf142a26 uvc-5951 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                        <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-72065b463bf142a26 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                          <h2 style={{fontWeight:"bold"}}> <p>Why <strong className='heading-second'>Fin</strong>Cetus Solutions?</p></h2>
                        </div>
                        <div className="uvc-heading-spacer line_with_icon" >
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='why-fincetus-container'>

                

                  {/* Why fincetus images */}
                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-12 vc_col-md-offset-0 vc_col-md-12 vc_col-sm-offset-2">
                    <div className="vc_column-inner vc_custom_1515424193637" style={{marginTop:"0px !important"}}>
                      <div className="wpb_wrapper">
                        <div className="shortcode-single-image-wrap alignnone" style={{marginTop:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px"}}>
                          <div className="shortcode-single-image">
                            <div className="fancy-media-wrap"><img className="lazy-load preload-me is-loaded" src={require("../images/whyfincetus.png")}  width="1000" height="700" data-dt-location="images/" style={{willChange: "auto"}} alt="" cust-srcset="images/about-us.jpg 1000w, images/about-us.jpg 1142w" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                 

                
                </div>

                {/* Message from Founder & CEO images */}
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 ceo-message-container" style={{marginBottom:"80px"}}>
                    <div className="vc_column-inner vc_custom_1515424193637">
                      <div className="wpb_wrapper">
                        <div className="shortcode-single-image-wrap alignnone" style={{marginTop:"0px", marginBottom:"0px", marginLeft:"0px", marginRight:"0px", width:"1000px"}}>
                          <div className="shortcode-single-image">
                          <div className='ceo-message'>Message from Founder & CEO</div>                      
                            <div className="fancy-media-wrap">
                              
                              <img className="lazy-load preload-me is-loaded" src={require("../images/jb-profile.png")}  width="1000" height="700" data-dt-location="images/" style={{willChange: "auto"}} alt="" cust-srcset="images/about-us.jpg 1000w, images/about-us.jpg 1142w" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  {/* Message from Founder & CEO message details */}
                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                    <div className="vc_column-inner vc_custom_1515424178786">
                      <div className="wpb_wrapper">
                        <div id="ultimate-heading-4425b474a5f32621" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-4425b474a5f32621 uvc-4956 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                          <div className="uvc-heading-spacer no_spacer"></div>
                          <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-4425b474a5f32621 h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:30px;&quot;,&quot;line-height&quot;:&quot;desktop:40px;&quot;}">
                            {/* <h3 style={{fontWeight:"bold", marginBottom:"20px"}}><p></p><p></p>We are <strong className='heading-second'>Fin</strong>Cetus Solutions.</h3> */}
                          </div>
                          <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target=".uvc-heading.ultimate-heading-4425b474a5f32621 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;desktop:26px;&quot;}" style={{fontWeight:"normal",marginBottom:"20px"}}>
                            <p> <strong className='ceo-message-content'> "It is important to prioritize financial literacy and objectives as they have a significant impact on the eight dimensions of wellness: physical, spiritual, emotional, intellectual, social, environmental, occupational, and financial wellness. Focusing on both your life and financial goals can help you attain greater freedom, choices, peace, and happiness in life." </strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
            </div>
          </div>
          
          
          

            <div className="wpb_column vc_column_container vc_col-sm-6 aboutus-mm-panel" style={{display:"none"}}>
              <div className="vc_column-inner vc_custom_1512326537201">
                <div className="wpb_wrapper">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                          <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                            <div className="shortcode-single-image">
                              <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a  className="layzr-bg rollover"><img className="lazy-load preload-me" src="images/profile-1.jpg" data-src="images/profile-1.jpg" data-srcset="images/profile-1.jpg 500w, images/profile-1.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                          <div id="ultimate-heading-67115b463bf147b28" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-67115b463bf147b28 uvc-5000 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                            <div className="uvc-heading-spacer no_spacer"></div>
                            <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>        
                              <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Joseph Basker – Director</h2>
                            </div>
                            <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>Strategic leader & Enterprise Architect with 12+ years of experience in various technologies and has strong interpersonal, technical, communication, problem solving and decision-making skills. Ability to establish and maintain strong alliances; lead and motivate teams; drive projects to successful completion; convey complex technical requirements/data; exceed customer expectations and achieve fiscal bottom-line results.</div>
                          </div>
                          <div className="right-widgets mini-widgets aboutus-mm-social" style={{display: "block"}}>
                            <div className="soc-ico show-on-desktop in-top-bar-right hide-on-second-switch disabled-bg accent-border border-on hover-accent-bg hover-accent-border hover-border-on">
                              <a title="Facebook" href="/" target="_blank" className="facebook"><span className="soc-font-icon"></span><span className="screen-reader-text">Facebook</span></a>
                              <a title="Twitter" href="/" target="_blank" className="twitter"><span className="soc-font-icon"></span><span className="screen-reader-text">Twitter</span></a>
                              <a title="YouTube" href="#" target="_blank" className="you-tube"><span className="soc-font-icon"></span><span className="screen-reader-text">YouTube</span></a>
                              <a title="Linkedin" href="#" target="_blank" className="linkedin"><span className="soc-font-icon"></span><span className="screen-reader-text">Linkedin</span></a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            


        
        </div>
      </div>
      {/* <Contact /> */}
      <CommonFooter />
    </>
  )
}

