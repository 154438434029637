import {Route, Routes, createBrowserRouter} from 'react-router-dom'
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import Preciousmetals from './Components/Preciousmetals';
import Service from './Components/Service';
import FinancialTraining from './Components/Service/FinancialTraining';
import MoneyManagement from './Components/Service/FinancialTraining/MoneyManagement';
import Investing from './Components/Service/FinancialTraining/Investing';
import Business from './Components/Service/FinancialTraining/Business';
import RealState from './Components/Service/FinancialTraining/RealState';
import Commoditie from './Components/Service/FinancialTraining/Commoditie';
import Gold from './Components/Service/FinancialTraining/Gold';
import Silver from './Components/Service/FinancialTraining/Silver';
import Stocks from './Components/Service/FinancialTraining/Stocks';
import MutualFunds from './Components/Service/FinancialTraining/MutualFunds';
import Bonds from './Components/Service/FinancialTraining/Bonds';
import Deposits from './Components/Service/FinancialTraining/Deposits';
import CryptoCurrency from './Components/Service/FinancialTraining/CryptoCurrency';
import Banking from './Components/Service/FinancialTraining/Banking';
import Insurance from './Components/Service/FinancialTraining/Insurance';
import MacroEconomics from './Components/Service/FinancialTraining/MacroEconomics';
import CustomTrainings from './Components/Service/FinancialTraining/CustomTrainings';
import FinancialConsulting from './Components/Service/FinancialConsulting';
import FinancialAnalysis from './Components/Service/FinancialConsulting/FinancialAnalysis';
import InvestmentAnalysis from './Components/Service/FinancialConsulting/InvestmentAnalysis';
import BusinessConsulting from './Components/Service/BusinessConsulting';
import Sales from './Components/Service/BusinessConsulting/Sales';
import Marketing from './Components/Service/BusinessConsulting/Marketing';
import Financial from './Components/Service/BusinessConsulting/Financial';
import Delivery from './Components/Service/BusinessConsulting/Delivery';
import Support from './Components/Service/BusinessConsulting/Support';
import Resources from './Components/Resources';
import Contact from './Components/Contact';
import Error from './Components/Error';
import UnderConstruction from './Components/UnderConstruction';

export const NavRouter = () => {
 
  return (
    <Routes>
        <Route path = "/" element = { <Home />} />
        <Route path = "/about" element = { <AboutUs /> } />
        <Route path = "/preciousmetals" element = { <Preciousmetals /> } />
        
        <Route path = "/service" element = { <Service /> } />
          <Route path = "/trainings" element = { <FinancialTraining /> } />          
            <Route path = "/money-management" element = { <UnderConstruction /> } /> { /* <MoneyManagement /> */ }
            <Route path = "/investing" element = { <UnderConstruction />  } /> { /* <Investing /> */ }
            <Route path = "/business" element = { <UnderConstruction /> } /> { /* <Business /> */ }
            <Route path = "/real-state" element = { <UnderConstruction /> } /> { /* <RealState /> */ }

            <Route path = "/commodities" element = { <UnderConstruction />} /> { /* <Commoditie /> */ }
            <Route path = "/gold" element = { <UnderConstruction /> } /> { /* <Gold /> */ }
            <Route path = "/silver" element = { <UnderConstruction /> } /> { /* <Silver /> */ }
            <Route path = "/stocks" element = { <UnderConstruction /> } /> { /* <Stocks /> */ }

            <Route path = "/mutual-funds" element = { <UnderConstruction /> } /> { /* <MutualFunds /> */ }
            <Route path = "/bonds" element = { <UnderConstruction /> } /> { /* <Bonds /> */ }
            <Route path = "/deposits" element = { <UnderConstruction /> } /> { /* <Deposits /> */ }
            <Route path = "/crypto-currency" element = { <UnderConstruction/>  } /> { /* <CryptoCurrency /> */ }

            <Route path = "/banking" element = { <UnderConstruction /> } /> { /* <Banking /> */ }
            <Route path = "/insurance" element = { <UnderConstruction /> } /> { /* <Insurance /> */ }
            <Route path = "/macro-economics" element = { <UnderConstruction />  } /> { /* <MacroEconomics /> */ }
            <Route path = "/custom-trainings" element = { <UnderConstruction /> } /> { /* <CustomTrainings /> */ }

          <Route path = "/financial-consulting" element = { <FinancialConsulting /> } /> 
            <Route path = "/financial-analysis" element = { <UnderConstruction />  } /> { /* <FinancialAnalysis /> */ }
            <Route path = "/investment-analysis" element = { <UnderConstruction /> } /> { /* <InvestmentAnalysis /> */ }

          <Route path = "/business-consulting" element = { <BusinessConsulting /> } />
            <Route path = "/sales" element = { <UnderConstruction /> } /> { /* <Sales /> */ }
            <Route path = "/marketing" element = { <UnderConstruction /> } /> { /* <Marketing /> */ }
            <Route path = "/financial" element = { <UnderConstruction />  } /> { /* <Financial /> */ }
            <Route path = "/delivery" element = { <UnderConstruction /> } /> { /* <Delivery /> */ }
            <Route path = "/support" element = { <UnderConstruction /> } />  { /* <Support /> */ }
        
        <Route path = "/resources" element = { <UnderConstruction /> } /> { /* <Resources /> */ }
        <Route path = "/contact" element = {  <Contact /> } />
        <Route path = "*" element = { <Error /> } />

        
      </Routes>
  )
}
