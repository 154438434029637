import './Contact.scss'
import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from 'react';


export default function Contact() {
  const form = useRef<any>();
  const name = useRef<any>();
  const email = useRef<any>();
  const mobileNumber = useRef<any>();
  const message = useRef<any>();

  
  useEffect(()=>{
    console.log("form....", form);
  },[]);
  const sendMail = () => {    
    alert("Your message has been sent successfully.");
  }
  const sendEmail = (e:any) => {
    e.preventDefault();

    emailjs
      .sendForm('service_j9204t4', 'template_q5drpmg', form.current, {
        publicKey: 'Z5k0EbeGRkK3Xkme6',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert("Your message has been sent successfully.");
          name.current.value = "";
          email.current.value = "";
          mobileNumber.current.value = "";
          message.current.value = "";

        },
        (error) => {
          console.log('FAILED...', error.text);
          alert("Your message has not sent successfully.");
        },
      );
  };
  return (
    <>
    <div className="page-title title-left solid-bg page-title-responsive-enabled"  >
      <div className="wf-wrap">
        <div className="page-title-head hgroup">
            <h1>Contact</h1>
        </div>
        {/* <div class="page-title-breadcrumbs">
            <div class="assistive-text">You are here:</div>
            <ol class="breadcrumbs text-small" xmlns:v="http://rdf.data-vocabulary.org/#">
                <li typeof="v:Breadcrumb"><a rel="v:url" property="v:title" href="https://the7.io/dental/" title="">Home</a></li>
                <li class="current">Contact</li>
            </ol>
        </div>  */}
      </div>
    </div>
    <div id="main" className="main-contacts sidebar-none sidebar-divider-off" style={{paddingTop:"40px", paddingBottom:"0px"}}>
      <div className="main-gradient"></div>
      <div className="wf-wrap">
        <div className="wf-container-main">
          <div id="content" className="content" role="main">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1515175347461 vc_row-o-content-top vc_row-flex">
              <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-6 vc_col-md-6">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper">
                    <div className="aio-icon-component  vc_custom_1514458781458  accent-icon-bg accent-border-color style_1">
                      <div id="Info-box-wrap-9111" className="aio-icon-box left-icon">
                        <div className="aio-icon-left">
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon advanced " style={{color:"#ffffff",borderStyle:"", borderColor:"#333333",borderWidth:"1px",width:"50px", height:"50px", lineHeight:"50px", borderRadius:"500px", fontSize:"24px",display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-map-marker"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aio-ibd-block">
                          <div className="aio-icon-header">
                            <h3 className="aio-icon-title ult-responsive" data-ultimate-target="#Info-box-wrap-9111 .aio-icon-title" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:16px;&quot;,&quot;line-height&quot;:&quot;desktop:16px;&quot;}" style={{fontWeight:"bold"}}>Address</h3>
                          </div>
                          <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-9111 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">Chennai, India, 600 091.</div>
                        </div>
                      </div>
                    </div>
                    <div className="aio-icon-component  vc_custom_1514458785737  accent-icon-bg accent-border-color style_1">
                      <div id="Info-box-wrap-7216" className="aio-icon-box left-icon">
                        <div className="aio-icon-left">
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon advanced " style={{color:"#ffffff", borderStyle:"", borderColor:"#333333", borderWidth:"1px", width:"50px", height:"50px", lineHeight:"50px", borderRadius:"500px", fontSize:"24px", display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-phone"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aio-ibd-block">
                          <div className="aio-icon-header">
                            <h3 className="aio-icon-title ult-responsive" data-ultimate-target="#Info-box-wrap-7216 .aio-icon-title" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:16px;&quot;,&quot;line-height&quot;:&quot;desktop:16px;&quot;}" style={{fontWeight:"bold"}}>Phone numbers</h3>
                          </div>
                          <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-7216 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">+91 97890 01881 </div>
                        </div>
                      </div>
                    </div>
                    <div className="aio-icon-component  vc_custom_1514458789888  accent-icon-bg accent-border-color style_1">
                      <div id="Info-box-wrap-7248" className="aio-icon-box left-icon">
                        <div className="aio-icon-left">
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon advanced " style={{color:"#ffffff",borderStyle:"", borderColor:"#333333", borderWidth:"1px", width:"50px", height:"50px", lineHeight:"50px", borderRadius:"500px", fontSize:"24px", display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-envelope"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aio-ibd-block">
                          <div className="aio-icon-header">
                            <h3 className="aio-icon-title ult-responsive" data-ultimate-target="#Info-box-wrap-7248 .aio-icon-title" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:16px;&quot;,&quot;line-height&quot;:&quot;desktop:16px;&quot;}">Email</h3>
                          </div>
                          <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-7248 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">contact@fincetus.com</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              

              <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-6 vc_col-md-6 vc_hidden-md1 vc_hidden-sm1 vc_hidden-xs1">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <div className="shortcode-single-image-wrap alignleft  vc_custom_1515175297662">
                      <div className="shortcode-single-image">
                        <div className="fancy-media-wrap"><img className="lazy-load preload-me is-loaded" src={require("../images/contact-us.jpg")} width="600" height="425" data-dt-location="images/" style={{willChange: "auto"}} alt="" cust-srcset="images/contact-us.jpg 600w, images/contact-us.jpg 1200w" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="menu_section" className="vc_row wpb_row vc_row-fluid vc_custom_1515175073570 vc_row-o-equal-height vc_row-flex">
              <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6 vc_col-has-fill">
                <div className="vc_column-inner vc_custom_1515175022452">
                  <div className="wpb_wrapper">
                    <div id="ultimate-heading-41795b47803cca619" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-41795b47803cca619 uvc-4449 " data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                      <div className="dynamic_ultimate_heading_css">
                        <style>
                            
                        </style>
                      </div>
                      <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-41795b47803cca619 h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:30px;&quot;,&quot;line-height&quot;:&quot;desktop:40px;&quot;}">
                          <h2 style={{fontWeight:"bold"}}>Contact Form</h2>
                      </div>
                      <div className="uvc-heading-spacer line_with_icon" style={{marginBottom: "40px", height: "21px"}}>
                        <div className="ult-just-icon-wrapper  ">
                          <div className="align-icon" style={{textAlign:"center"}}>
                            <div className="aio-icon none " style={{color:"red", fontSize:"18px", display:"inline-block"}}>
                              <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <form className="dt-contact-form dt-form" action="mail.php" method="post">
                      <input type="hidden" name="widget_id" value="presscore-contact-form-widget--1" onChange={()=> {}} />
                      <input type="hidden" name="send_message"  onChange={()=> {}} />
                      <div className="form-fields">
                        <span className="form-name">
                          <label className="assistive-text">Name *</label>
                          <input id="name" type="text" className="validate[required]" onChange={()=> {}} placeholder="Name *" name="name"  aria-required="true" />
                        </span>
                        <span className="form-mail"><label className="assistive-text">E-mail *</label>
                          <input id="email" type="text" className="validate[required,custom[email]]" placeholder="E-mail *" name="email"  aria-required="true" onChange={()=> {}} />
                        </span>
                        <span className="form-telephone"><label className="assistive-text">Telephone</label>
                          <input id="phone" type="text" placeholder="Telephone" name="telephone"  aria-required="false" onChange={()=> {}}  />
                        </span>
                      </div><span className="form-message"><label className="assistive-text1">Message</label><textarea placeholder="Message" name="message" cust-rows="7" aria-required="false"></textarea>
                      </span><p><a  className="dt-btn dt-btn-m dt-btn-submit contact-submit" rel="nofollow"><span>Send message</span> <input id="msg" className="assistive-text1" type="submit" value="submit" onClick={() => sendMail()}  /></a></p>
                    </form> */}
                    <form className="dt-contact-form dt-form" ref={form} onSubmit={sendEmail}>
                      <input type="hidden" name="widget_id" value="presscore-contact-form-widget--1" onChange={()=> {}} />
                      <input type="hidden" name="send_message"  onChange={()=> {}} />
                      <div className="form-fields">
                        <span className="form-name">
                          <label className="assistive-text">Name *</label>
                          <input id="name" name="user_name" type="text" className="validate[required]" ref={name} onChange={()=> {}} placeholder="Name *"  aria-required="true" />
                        </span>
                        <span className="form-mail"><label className="assistive-text">E-mail *</label>
                          <input ref={email} id="email" name="user_email" type="text" className="validate[required,custom[email]]" placeholder="E-mail *"  aria-required="true" onChange={()=> {}} />
                        </span>
                        <span className="form-telephone"><label className="assistive-text">Telephone</label>
                          <input ref={mobileNumber} id="phone" type="text" placeholder="Telephone" name="telephone"  aria-required="false" onChange={()=> {}}  />
                        </span>
                      </div>
                      <span className="form-message">
                        <label className="assistive-text1">Message</label>
                        <textarea ref={message} placeholder="Message" name="message" cust-rows="7" aria-required="false"></textarea>
                      </span><p><a  className="dt-btn dt-btn-m dt-btn-submit contact-submit" rel="nofollow"><span>Send message</span> <input id="msg" className="assistive-text1"  type="submit" value="submit"  /></a></p>
                    </form>
                    {/* <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="user_name" />
                    <label>Email</label>
                    <input type="email" name="user_email" />
                    <label>Message</label>
                    <textarea name="message" />
                    <input type="submit" value="Send" />
                  </form> */}
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-0 vc_col-md-6 vc_col-sm-offset-0 vc_col-has-fill" >
                <div className="vc_column-inner vc_custom_1515175005736">
                  <div className="wpb_wrapper">
                    <div id="ultimate-heading-23165b47803cca9cb" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-23165b47803cca9cb uvc-1222 " data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                        <div className="dynamic_ultimate_heading_css ">
                            <style>
                                
                            </style>
                        </div>
                        <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-23165b47803cca9cb h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:30px;&quot;,&quot;line-height&quot;:&quot;desktop:40px;&quot;}">
                            <h2 style={{fontWeight:"bold"}}>Directions</h2>
                        </div>
                        <div className="uvc-heading-spacer line_with_icon" style={{marginBottom: "40px", height: "21px"}}>
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon none " style={{color:"red", fontSize:"18px", display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div id="wrap_map_5b47803ccad65" className="ultimate-map-wrapper ult-adjust-bottom-margin " style={{marginBottom:"30px", padding:"0px"}}>                                            
                      <div className="map ultimate_google_map" id="map" style={{height: "400px"}} >
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
