import React from 'react'
import './Preciousmetals.scss'
import CommonFooter from './CommonFooter';
import Contact from './Contact';
function Preciousmetals() {
  const preciousMetalImgList:any = [
    "pre-fin-rose.png", 
    "pre-fin-lotus.png", 
    // "../images/pre-fin-black-rose.png", 
    // "../images/pre-black-lotus.png",
    // "../images/pre-fin-banyantree.png",
    // "../images/pre-fin-goddess-lakshmi.png",

    // "../images/pre-fin-goddess-lakshmi-balaji.png",
    // "../images/pre-fin-lord-ayyappa.png",
    // "../images/pre-fin-goodess-lakshmi2.png",
    // "../images/pre-fin-goodess-lakshmi-ganesh.png",
    // "../images/pre-fin-goodess-lord-ganesha.png",
    // "../images/pre-fin-goodess-lakshmi-ganesh1.png",

    // "../images/pre-fin-lord-jesus.png",
    // "../images/pre-fin-lord-jesus1.png",
    // "../images/pre-fin-goodess-lakshmiganesh.png",
    // "../images/pre-fin-goodess-stylized-lakshmiganesh.png",
    // "../images/pre-fin-goddess-lakshmi-balaji1.png",

    // "../images/pre-fin-guru-nanak-devji.png",
    // "../images/pre-fin-premium-coloured.png",
    // "../images/pre-fin-oval-pendant.png",
    // "../images/pre-fin-bihu.png",
    // "../images/pre-fin-tola.png",
    // "../images/pre-fin-personalised.png"
  ]
  return (
    <>
      {/* <!-- service page content2 --> */}
        <div id="main" className="precious-metals sidebar-none sidebar-divider-off" style={{paddingTop:"0px", paddingBottom:"0px"}}>
          <div className="main-gradient"></div>
          <div className="wf-wrap">
            <div className="wf-container-main">
              <div id="content" className="content" role="main">
                <div id="about" className="vc_row wpb_row vc_row-fluid vc_custom_1515424261504 vc_row-o-content-middle vc_row-flex">
                            
                  
                  <div className="vc_row wpb_row vc_row-fluid vc_custom_1515405673647" style={{marginTop:"20px", marginBottom:"20px"}}>
                    
                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                      <div className="vc_column-inner vc_custom_1515176114270">
                        <div className="wpb_wrapper">
                          <div id="ultimate-heading-72065b463bf142a26" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-72065b463bf142a26 uvc-5951 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                            <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-72065b463bf142a26 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                              <h2 style={{fontWeight:"bold"}}>PRECIOUS METALS</h2>
                            </div>
                            <div className="uvc-heading-spacer line_with_icon" >
                              <div className="ult-just-icon-wrapper  ">
                                <div className="align-icon" style={{textAlign:"center"}}>
                                  <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                    <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  {/* <!-- sub panel desing begin --> */}

                  

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                      <div className="vc_column-inner vc_custom_1512326537201">
                        <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                <div className="shortcode-single-image">
                                                    <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-rose.png")} data-src="../images/pre-fin-rose.png" data-srcset="images/pre-fin-rose.png 500w, images/pre-fin-rose.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                    <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                    </a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-lotus.png")} data-src="../images/pre-fin-lotus.png" data-srcset="images/pre-fin-lotus.png 500w, images/pre-fin-lotus.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-black-rose.png")} data-src="../images/pre-fin-black-rose.png" data-srcset="images/pre-fin-black-rose.png 500w, images/pre-fin-black-rose.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-black-lotus.png")} data-src="../images/pre-black-lotus.png" data-srcset="images/pre-black-lotus.png 500w, images/pre-black-lotus.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-banyantree.png")} data-src="../images/pre-fin-banyantree.png" data-srcset="images/pre-fin-banyantree.png 500w, images/pre-fin-banyantree.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goddess-lakshmi.png")} data-src="../images/pre-fin-goddess-lakshmi.png" data-srcset="images/pre-fin-goddess-lakshmi.png 500w, images/pre-fin-goddess-lakshmi.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    


                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goddess-lakshmi-balaji.png")} data-src="../images/pre-fin-goddess-lakshmi-balaji.png" data-srcset="images/pre-fin-goddess-lakshmi-balaji.png 500w, images/pre-fin-goddess-lakshmi-balaji.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-lord-ayyappa.png")} data-src="../images/pre-fin-lord-ayyappa.png" data-srcset="images/pre-fin-lord-ayyappa.png 500w, images/pre-fin-lord-ayyappa.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goodess-lakshmi2.png")} data-src="../images/pre-fin-goodess-lakshmi2.png" data-srcset="images/pre-fin-goodess-lakshmi2.png 500w, images/pre-fin-goodess-lakshmi2.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goodess-lakshmi-ganesh.png")} data-src="../images/pre-fin-goodess-lakshmi-ganesh.png" data-srcset="images/pre-fin-goodess-lakshmi-ganesh.png 500w, images/pre-fin-goodess-lakshmi-ganesh.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goodess-lord-ganesha.png")} data-src="../images/pre-fin-goodess-lord-ganesha.png" data-srcset="images/pre-fin-goodess-lord-ganesha.png 500w, images/pre-fin-goodess-lord-ganesha.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    
                    
                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goodess-lakshmi-ganesh1.png")} data-src="../images/pre-fin-goodess-lakshmi-ganesh1.png" data-srcset="images/pre-fin-goodess-lakshmi-ganesh1.png 500w, images/pre-fin-goodess-lakshmi-ganesh1.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-lord-jesus.png")} data-src="../images/pre-fin-lord-jesus.png" data-srcset="images/pre-fin-lord-jesus.png 500w, images/pre-fin-lord-jesus.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-lord-jesus1.png")} data-src="../images/pre-fin-lord-jesus1.png" data-srcset="images/pre-fin-lord-jesus1.png 500w, images/pre-fin-lord-jesus1.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goodess-lakshmiganesh.png")} data-src="../images/pre-fin-goodess-lakshmiganesh.png" data-srcset="images/pre-fin-goodess-lakshmiganesh.png 500w, images/pre-fin-goodess-lakshmiganesh.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goodess-stylized-lakshmiganesh.png")} data-src="../images/pre-fin-goodess-stylized-lakshmiganesh.png" data-srcset="images/pre-fin-goodess-stylized-lakshmiganesh.png 500w, images/pre-fin-goodess-stylized-lakshmiganesh.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-goddess-lakshmi-balaji1.png")} data-src="../images/pre-fin-goddess-lakshmi-balaji1.png" data-srcset="images/pre-fin-goddess-lakshmi-balaji1.png 500w, images/pre-fin-goddess-lakshmi-balaji1.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-guru-nanak-devji.png")} data-src="../images/pre-fin-guru-nanak-devji.png" data-srcset="images/pre-fin-guru-nanak-devji.png 500w, images/pre-fin-guru-nanak-devji.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-premium-coloured.png")} data-src="../images/pre-fin-premium-coloured.png" data-srcset="images/pre-fin-premium-coloured.png 500w, images/pre-fin-premium-coloured.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-oval-pendant.png")} data-src="../images/pre-fin-oval-pendant.png" data-srcset="images/pre-fin-oval-pendant.png 500w, images/pre-fin-oval-pendant.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-bihu.png")} data-src="../images/pre-fin-bihu.png" data-srcset="images/pre-fin-bihu.png 500w, images/pre-fin-bihu.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-tola.png")} data-src="../images/pre-fin-tola.png" data-srcset="images/pre-fin-tola.png 500w, images/pre-fin-tola.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wpb_column vc_column_container vc_col-sm-6 service-serices-panel">
                        <div className="vc_column-inner vc_custom_1512326537201">
                            <div className="wpb_wrapper">
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                                    <div className="shortcode-single-image">
                                                        <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="" className="layzr-bg1 rollover1"><img className="lazy-load1 preload-me1" src={require("../images/pre-fin-personalised.png")} data-src="../images/pre-fin-personalised.png" data-srcset="images/pre-fin-personalised.png 500w, images/pre-fin-personalised.png 800w" width="500" height="500" data-dt-location="images/" alt="" />
                                                        <h4 className = "service-serices-txt"style={{fontWeight:"bold", marginBottom:"10px"}}></h4>
                                                        </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* <!-- sub panel desing end --> */}

                        
                  </div>
                </div>
              </div>

                     
            </div>
          </div>
        </div>

      {/* <Contact /> */}
      <CommonFooter />
    </>
  )
}

export default Preciousmetals;