import React from 'react'
import underConstructionImg from '../images/underconstruction.png'

function UnderConstruction() {
  return (
    <>
      <div className='under-construction-site'>
        <img src={underConstructionImg} />
      </div>
    </>
  )
}

export default UnderConstruction