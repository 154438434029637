import React from 'react'

import './Home.scss'
import Contact from './Contact'
import CommonFooter from './CommonFooter'


export default function Home() {
  return (
    // <!-- index page content2 -->
    <>
    <div id="main" className="home-page homepage sidebar-none sidebar-divider-off" style={{paddingTop:"0px", paddingBottom:"0px"}}>
      <div className="main-gradient"></div>
      <div className="wf-wrap">
        <div className="wf-container-main">
          <div id="content" className="content" role="main">
            <div id="about" className="vc_row wpb_row vc_row-fluid vc_custom_1509351079558">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper">
                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                      
                      {/*Home page introductions messages.  */}
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                        <div className="vc_column-inner vc_custom_1509566455479">
                          <div className="wpb_wrapper">
                            <div className="shortcode-single-image-wrap alignnone  vc_custom_1512311389137">
                              <div className="shortcode-single-image">
                                <div className="fancy-media-wrap">
                                  <div className="layzr-bg1 rollover-video1">
                                    <br /> <br /> <br /> <br /> <br />
                                    <h2 className='core-msg'>“Advance in life by aiming for a higher standard of living”</h2>
                                    <br /> <br /> <br /> <br /> <br /> <br />
                                    {/* <div className='main-heading'>At <strong className='heading-second'>Fin</strong>  */}
                                     <h2>
                                     <div className='main-heading main-message'><strong className='heading-second'>Fin</strong>Cetus mission is to help clients achieve financial freedom and live fulfilling lives, providing assistance in challenging circumstances to individuals and businesses worldwide. </div> </h2>
                                    {/* <img className="lazy-load preload-me" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 1000w, images/fin-consulting.jpg 1200w" width="1000" height="600" data-dt-location="images/fin-consulting.jpg" alt="" /> */}
                                  {/* <!-- <a className="video-icon dt-pswp-item pswp-video" href="https://www.youtube.com/watch?v=YEi_gUElWzA1111" title="" data-dt-img-description=""></a> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>                              
                          </div>
                        </div>
                      </div>

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                        <div className="vc_column-inner vc_custom_1509566455479">
                          <div className="wpb_wrapper">
                            <div className="shortcode-single-image-wrap alignnone  vc_custom_1512311389137">
                              <div className="shortcode-single-image">
                                <div className="fancy-media-wrap">
                                  <div className="layzr-bg1 rollover-video1">
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/gold-tree.png")}     data-src={require("../images/gold-tree.png")}     data-srcset="images/fin-gold-selver.jpg 1000w, images/fin-gold-selver.jpg 1200w" width="1000" height="600"                data-dt-location={require("../images/gold-tree.png")} alt="" />
                                  {/* <!-- <a className="video-icon dt-pswp-item pswp-video" href="https://www.youtube.com/watch?v=YEi_gUElWzA1111" title="" data-dt-img-description=""></a> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                              
                          </div>
                        </div>
                      </div>

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                        <div className="vc_column-inner vc_custom_1515176114270">
                          <div className="wpb_wrapper">
                            <div id="ultimate-heading-72065b463bf142a26" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-72065b463bf142a26 uvc-5951 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                              <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-72065b463bf142a26 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                                <h2 style={{fontWeight:"bold"}}>Welcome to FinCetus</h2>
                              </div>
                              <div className="uvc-heading-spacer line_with_icon" >
                                <div className="ult-just-icon-wrapper  ">
                                  <div className="align-icon" style={{textAlign:"center"}}>
                                    <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                      <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                        <div className="vc_column-inner vc_custom_1509566445127">
                          <div className="wpb_wrapper">
                            <div id="ultimate-heading-82995b463bf142c1a" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-82995b463bf142c1a uvc-2368 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                              <div className="uvc-heading-spacer no_spacer"></div>
                              <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-82995b463bf142c1a h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>We are FinCetus</h2>
                              </div>
                              <div className="uvc-sub-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-82995b463bf142c1a .uvc-sub-heading ' data-responsive-json-new='{"font-size":"","line-height":"desktop:26px;"}' style={{fontWeight:"normal",marginBottom:"20px",textAlign: "justify",color:"#333"}}><p>We are <strong className='heading-second'>Fin</strong> <strong className='name-secondpart' style={{marginLeft:"-6px"}}> Cetus Solutions Pvt. Ltd. </strong> was founded on February 5th, 2024, in Chennai, India. Our main goal is to empower individuals with financial skills to achieve financial freedom, leading to greater choices, well-being, and a higher standard of living. </p>
                                <p></p>
                                <p>By enhancing financial knowledge and skills, individuals can achieve various life goals, manage finances effectively, make informed investment decisions, avoid financial fraud, prepare for emergencies, manage debt, reduce financial stress, and make better financial decisions.</p>
                                <p></p>
                                <p>Our Business Consulting solutions are designed to help individuals who are passionate about starting and managing their businesses. Our main objective is to empower new entrepreneurs to achieve success through free-market capitalism.</p>
                                <p></p>
                                <p>We offer LBMA-certified gold (24K) and silver bullion products with 999.9 purity and Swiss craftsmanship.</p>
                              </div>
                            </div><a href="about.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1516622183883" id="default-btn-a54cb064c48eb3b7a7173602fad8c835" style={{width:"180px"}}><span>More about us</span></a></div>
                        </div>
                      </div>

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                        <div className="vc_column-inner vc_custom_1509566455479">
                          <div className="wpb_wrapper">
                            <div className="shortcode-single-image-wrap alignnone  vc_custom_1512311389137">
                              <div className="shortcode-single-image">
                                <div className="fancy-media-wrap">
                                  <div className="layzr-bg1 rollover-video"><img className="lazy-load1 preload-me1" src={require("../images/fin-metting-1.jpg")} data-src={require("../images/fin-metting-1.jpg")} data-srcset= "./assets/images/fin-metting-1.jpg 1000w, ./assets/images/fin-metting-1.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-metting-1.jpg")} alt="" />
                                    {/* <img src={require('../images/fin-metting-1.jpg')} /> */}
                                    {/* <!-- <a className="video-icon dt-pswp-item pswp-video" href="https://www.youtube.com/watch?v=YEi_gUElWzA1111" title="" data-dt-img-description=""></a> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>                              
                          </div>
                        </div>
                      </div>

                    </div>                      
                  </div>
                </div>
              </div>
            </div>            

            <div className="upb_color" data-bg-override="ex-full" data-bg-color="#f7f7f7" data-fadeout="" data-fadeout-percentage="30" data-parallax-content="" data-parallax-content-sense="30" data-row-effect-mobile-disable="true" data-img-parallax-mobile-disable="true" data-rtl="false" data-custom-vc-row="" data-vc="5.4.7" data-is_old_vc="" data-theme-support="" data-overlay="false" data-overlay-color="" data-overlay-pattern="" data-overlay-pattern-opacity="" data-overlay-pattern-size=""></div>

              <div className="home-page-our-solutions vc_row wpb_row vc_row-fluid vc_custom_1512322712693 vc_row-o-equal-height vc_row-flex home-page-service">
                
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                  
                  <div className="vc_column-inner vc_custom_1515176114270">
                    <div className="wpb_wrapper">
                      <div id="ultimate-heading-72065b463bf142a26" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-72065b463bf142a26 uvc-5951 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                        <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-72065b463bf142a26 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                          <h2 style={{fontWeight:"bold"}}>Core Offerings</h2>
                        </div>
                        <div className="uvc-heading-spacer line_with_icon" >
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-4 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326537201">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="solutions-unified-communication.html" className="layzr-bg rollover">
                                    {/* <img className="lazy-load preload-me" src="images/fin-training.jpg" data-src="images/fin-training.jpg" data-srcset="images/fin-training.jpg 500w, images/fin-training.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/fin-training.jpg")}     data-src={require("../images/fin-training.jpg")}     data-srcset="images/fin-gold-selver.jpg 1000w, images/fin-gold-selver.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-training.jpg")} alt="" />
                                    </a>
                                    </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-67115b463bf147b28" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-67115b463bf147b28 uvc-5000 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>        
                                  <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Trainings</h2>
                                </div>
                                <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>FinCetus offers customized training on macroeconomics, monetary systems, money management, asset classes, and investing.</div><br/>
                              </div><a href="/trainings" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-4 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href1="solutions-contact-center.html" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/fin-consulting.jpg")} data-src={require("../images/fin-consulting.jpg")} data-srcset="images/fin-consulting.jpg 1000w, images/fin-consulting.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-consulting.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Consulting</h2>
                                </div>
                                <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div>
                              </div><a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpb_column vc_column_container vc_col-sm-4 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326544626">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320837278">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap"><a cust-href1="solutions-custom-applications-development.html" className="layzr-bg rollover">
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/fin-Precious-metals.jpg")} data-src={require("../images/fin-Precious-metals.jpg")} data-srcset="images/fin-Precious-metals.jpg 1000w, images/fin-Precious-metals.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-Precious-metals.jpg")} alt="" /> </a>
                                    
                                    {/* <img className="lazy-load preload-me" src="images/fin-Precious-metals.jpg" data-src="images/fin-Precious-metals.jpg" data-srcset="images/fin-Precious-metals.jpg 500w, images/fin-Precious-metals.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-145b463bf148e9d" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-145b463bf148e9d uvc-6969 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-145b463bf148e9d h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                  <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Precious Metals</h2>
                                </div>
                                <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-145b463bf148e9d .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>Precious metals serve as money, commodities, investments, and wealth insurance, providing greater financial stability.</div><br/>
                            </div>
                            <a href='/business-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="loop-slider vc_row wpb_row vc_row-fluid vc_custom_1512322712693 vc_row-o-equal-height vc_row-flex home-page-service">
               
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                  
                  <div className="vc_column-inner vc_custom_1515176114270">
                    <div className="wpb_wrapper">
                      <div id="ultimate-heading-72065b463bf142a26" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-72065b463bf142a26 uvc-5951 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                        <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-72065b463bf142a26 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                          <h2 style={{fontWeight:"bold"}}>100+ Financial Ideas & Solutions</h2>
                        </div>
                        <div className="uvc-heading-spacer line_with_icon" >
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='tag-list'>
                  <div className="slider r_to_l list-items loop-slider">
                    <div className="slider_inner">
                      <div className='tag'>Financial Trainings</div>
                      <div className='tag'>Money Management</div>
                      <div className='tag'>Investing</div>
                      <div className='tag'>Business</div>
                      <div className='tag'>Real Estate</div>
                      <div className='tag'>Commodities</div>
                      <div className='tag'>Precious Metals</div>
                      <div className='tag'>Gold</div>
                      <div className='tag'>Silver</div>
                      <div className='tag'>Stocks</div>
                      <div className='tag'>Mutual Funds</div>
                      <div className='tag'>Bonds</div>
                      <div className='tag'>Deposits</div>
                    </div>
                    <div className="slider_inner">
                      <div className='tag'>Crypto Currency</div>
                      <div className='tag'>Bitcoin</div>
                      <div className='tag'>Banking</div>
                      <div className='tag'>Insurance</div>
                      <div className='tag'>Custom Trainings</div>
                      <div className='tag'>Macro Economics</div>
                      <div className='tag'>Wealth</div>
                      <div className='tag'>Asset Class</div>
                      <div className='tag'>Inflation</div>
                      <div className='tag'>Financial Consulting</div>
                      <div className='tag'>Financial Analysis</div>
                      <div className='tag'>Investment Analysis</div>
                      <div className='tag'>Sales</div>
                      <div className='tag'>Marketing</div>
                      <div className='tag'>Business Finance</div>
                    </div>
                  </div>

                  <div className="slider l_to_r list-items loop-slider">
                    <div className="slider_inner">
                      <div className='tag'>Delivery</div>
                      <div className='tag'>Business Startup</div>
                      <div className='tag'>Business Support</div>
                      <div className="tag"> Fixed Deposits </div>
                      <div className="tag"> Recurring Deposits</div>
                      <div className="tag"> Fixed Deposits</div>
                      <div className="tag"> Recurring Deposits</div>
                      <div className="tag"> Startup Ideas</div>
                      <div className="tag"> Business Ideas</div>
                      <div className="tag"> Test Business Ideas</div>
                      <div className="tag"> Validate Business Ideas</div>
                      <div className="tag"> Market Research</div>
                      <div className="tag"> Business Plan</div>
                      <div className="tag"> Business Branding</div>
                    </div>
                    <div className="slider_inner">
                      <div className="tag"> Legal Compliance</div>
                      <div className="tag"> Company Incorporation</div>
                      <div className="tag"> Mentorship</div>
                      <div className="tag"> Taxes</div>
                      <div className="tag"> Net Worth</div>
                      <div className="tag"> Risks</div>
                      <div className="tag"> Emergency Funds</div>
                      <div className="tag"> Financial Crisis</div>
                      <div className="tag"> Crisis Management</div>
                      <div className="tag"> Economic Crisis</div>
                      <div className="tag"> Estate Planning</div>
                      <div className="tag"> SWOT Analysis</div>
                      <div className="tag"> Investment Returns</div>
                      <div className="tag"> Investment Risks</div>
                    </div>
                  </div>


                  <div className="slider r_to_l list-items loop-slider">
                    <div className="slider_inner">
                      <div className="tag"> Investment Performance</div>
                      <div className="tag"> Cash Flows</div>
                      <div className="tag"> ROIs</div>
                      <div className="tag"> Nominal Returns</div>
                      <div className="tag"> Real Returns</div>
                      <div className="tag"> Deflation</div>
                      <div className="tag"> Gold Coins</div>
                      <div className="tag"> Gold Bars</div>
                      <div className="tag"> Silver Coins</div>
                      <div className="tag"> Silver Bars</div>
                      <div className="tag"> 999.9% Purity</div>
                      <div className="tag"> Swiss Craftsmanship</div>
                      <div className="tag"> LBMA Accredited</div>
                      <div className="tag"> Financial Freedom</div>
                    </div>
                    <div className="slider_inner">
                      <div className="tag"> Financial Education</div>
                      <div className="tag"> Financial Literacy</div>
                      <div className="tag"> Financial Education</div>
                      <div className="tag"> Financial Literacy</div>
                      <div className="tag"> Income Analysis</div>
                      <div className="tag"> Expense Analysis</div>
                      <div className="tag"> Financial Ratios</div>
                      <div className="tag"> Savings Ratio</div>
                      <div className="tag"> Tax Analysis</div>
                      <div className="tag"> Asset Analysis</div>
                      <div className="tag"> Liabilities Analysis</div>
                      <div className="tag"> Networth Calculations</div>
                      <div className="tag"> Tax Analysis</div>
                      <div className="tag"> Asset Analysis</div>
                    </div>
                  </div>

                  <div className="slider l_to_r list-items loop-slider">
                    <div className="slider_inner">
                      <div className="tag"> Liabilities Analysis</div>
                      <div className="tag"> Networth Calculations</div>
                      <div className="tag"> Asset Allocation %</div>
                      <div className="tag"> Diversification Analysis</div>
                      <div className="tag"> Investment Ratios</div>
                      <div className="tag"> ROI Calculations</div>
                      <div className="tag"> Insurance Analysis</div>
                      <div className="tag"> Home Insurance</div>
                      <div className="tag"> Life Insurance</div>
                      <div className="tag"> Health Insurance</div>
                      <div className="tag"> Motor Insurance</div>
                      <div className="tag"> Emergency Preparation</div>
                      <div className="tag"> Financial Best Practices</div>
                    </div>
                    <div className="slider_inner">
                      <div className="tag"> Life Goals</div>
                      <div className="tag"> Financial Goals</div>
                      <div className="tag"> Short Term Issues</div>
                      <div className="tag"> Long Term Issues</div>
                      <div className="tag"> Retirement</div>
                      <div className="tag"> Tax Rates</div>
                      <div className="tag"> Tax on Dividends</div>
                      <div className="tag"> Tax on Interest</div>
                      <div className="tag"> Tax on Capital Gains</div>
                      <div className="tag"> Long Term Capital Gains</div>
                      <div className="tag"> Short Term Capital Gains</div>
                      <div className="tag"> Market Risks</div>
                      <div className="tag"> Credit Risks</div>
                    </div>
                  </div>

                  <div className="slider r_to_l list-items loop-slider">
                    <div className="slider_inner">
                      <div className="tag"> Liquidity Risks</div>
                      <div className="tag"> Scenario Analysis</div>
                      <div className="tag"> Sensitivity Analysis</div>
                      <div className="tag"> PEST Analysis</div>
                      <div className="tag"> Risk Tolerance</div>
                      <div className="tag"> Asset Reallocation</div>
                      <div className="tag"> Residential Real Estate</div>
                      <div className="tag"> Risk Tolerance</div>
                      <div className="tag"> Asset Reallocation</div>
                      <div className="tag"> Residential Real Estate</div>
                      <div className="tag"> Commercial Real Estate</div>
                      <div className="tag"> Industrial Real Estate</div>
                      <div className="tag"> Agricultural Real Estate</div>
                    </div>
                    <div className="slider_inner">
                      <div className="tag"> Metals</div>
                      <div className="tag"> Base Metals</div>
                      <div className="tag"> Technology Metals</div>
                      <div className="tag"> Energy </div>
                      <div className="tag"> Crude Oil</div>
                      <div className="tag"> Natural Gas</div>
                      <div className="tag"> Green Energy</div>
                      <div className="tag"> Industrial Metals</div>
                      <div className="tag"> Cash</div>
                      <div className="tag"> Currencies</div>
                      <div className="tag"> Debentures</div>
                      <div className="tag"> REITs</div>
                      <div className="tag"> Budget</div>
                    </div>
                  </div>
                  <div className="fade"></div>
                </div>


              </div>

              <br />
              

              <div className="upb_grad" data-grad="background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0C5D9A), color-stop(100%, #3DB4EF));background: -moz-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: -webkit-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: -o-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: -ms-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);" data-bg-override="ex-full" data-upb-overlay-color="" data-upb-bg-animation="" data-fadeout="" data-fadeout-percentage="30" data-parallax-content="" data-parallax-content-sense="30" data-row-effect-mobile-disable="true" data-img-parallax-mobile-disable="true" data-rtl="false" data-custom-vc-row="" data-vc="5.4.7" data-is_old_vc="" data-theme-support="" data-overlay="false" data-overlay-color="" data-overlay-pattern="" data-overlay-pattern-opacity="" data-overlay-pattern-size=""></div>
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1512322712693 vc_row-o-equal-height vc_row-flex" style={{display: "none"}}>
                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div id="ultimate-heading-30545b463bf146ed1" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-30545b463bf146ed1 uvc-4776 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                          <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-30545b463bf146ed1 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                            <h2 style={{fontWeight:"bold"}}>Our Services</h2>
                          </div>
                          <div className="uvc-heading-spacer line_with_icon" style={{marginBottom:"30px",height:"18px"}}>
                            <div className="ult-just-icon-wrapper  ">
                              <div className="align-icon" style={{textAlign:"center"}}>
                                <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                  <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="uvc-sub-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-30545b463bf146ed1 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:22px;","line-height":"desktop:34px;"}' style={{fontWeight:"normal",marginBottom:"60px",display: "none"}}>BluCetus services are grouped into six categories: Business Consulting, Technology Consulting, Management Consulting, Professional Services, Support Services and Training Services.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1512326537201">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                  <div className="shortcode-single-image">
                                      {/* <!-- <div className="fancy-media-wrap" style="border-radius:500px;"><a href="service-business-consulting.html" className="layzr-bg rollover" style="border-radius:500px;"><img className="lazy-load preload-me" src="images/home-business consulting.jpg" data-src="images/home-business consulting.jpg" data-srcset="images/home-business consulting.jpg 500w, images/home-business consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a></div> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                  <div id="ultimate-heading-67115b463bf147b28" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-67115b463bf147b28 uvc-5000 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                                    <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                    <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                      <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Business Consulting</h2>
                                    </div>
                                    <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>Our business consultants provide valuable consultation to organizations that helps them in improving their performance and efficiency. This is achieved by gathering business requirements, analyze business issues and create solutions that will help these organizations to achieve their goals.</div>
                                  </div><a href="service-business-consulting.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1512326540958">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                  <div className="shortcode-single-image">
                                      {/* <!-- <div className="fancy-media-wrap" style="border-radius:500px;"><a href="service-technology-consulting.html" className="layzr-bg rollover" style="border-radius:500px;"><img className="lazy-load preload-me" src="images/home-technology-consulting.jpeg" data-src="images/home-technology-consulting.jpeg" data-srcset="images/home-technology-consulting.jpeg 500w, images/home-technology-consulting.jpeg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a></div> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                  <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                                    <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                    <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                      <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Technology Consulting</h2>
                                    </div>
                                    <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>One of the biggest challenges for any organization is to adopt latest technology and endure competition in their respective line of business. BluCetus Technology Consultation enables every organization to incorporate latest technology and enhance business.</div>
                                  </div><a href="service-technology-consulting.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1512326544626">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320837278">
                                  <div className="shortcode-single-image">
                                    {/* <!-- <div className="fancy-media-wrap" style="border-radius:500px;"><a href="service-management-consulting.html" className="layzr-bg rollover" style="border-radius:500px;"><img className="lazy-load preload-me" src="images/home-management-consulting.jpeg" data-src="images/home-management-consulting.jpeg" data-srcset="images/home-management-consulting.jpeg 500w, images/home-management-consulting.jpeg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a></div> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div id="ultimate-heading-145b463bf148e9d" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-145b463bf148e9d uvc-6969 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                                  <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                  <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-145b463bf148e9d h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Management Consulting</h2>
                                  </div>
                                  <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-145b463bf148e9d .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>Our Management Consultants help organizations to solve issues, create values, maximize growth and improve business performance. By using business skills, we provide objective advice and expertise to organizations and help them develop specific skills that they are lacking.</div>
                                </div><a href="service-management-consulting.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1512326548253">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320649384">
                                  <div className="shortcode-single-image">
                                      {/* <!-- <div className="fancy-media-wrap" style="border-radius:500px;"><a href="service-professional-services.html" className="layzr-bg rollover" style="border-radius:500px;"><img className="lazy-load preload-me" src="images/home-professional-services.jpg" data-src="images/home-professional-services.jpg" data-srcset="images/home-professional-services.jpg 500w, images/home-professional-services.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a></div> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                  <div id="ultimate-heading-9395b463bf14990a" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-9395b463bf14990a uvc-4499 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                                    <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                    <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-9395b463bf14990a h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                      <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Professional Services</h2>
                                    </div>
                                    <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-9395b463bf14990a .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>BluCetus Professional Services team is specialized in Unified Communications, Contact Center and Custom Applications technology. We thrive under a strong, well-structured organization that brings consistency in excellence that ensures smooth coordination and transition in delivering projects.</div>
                                  </div><a href="service-professional-services.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1512326554170">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320932737">
                                  <div className="shortcode-single-image">
                                      {/* <!-- <div className="fancy-media-wrap" style="border-radius:500px;"><a href="service-support-services.html" className="layzr-bg rollover" style="border-radius:500px;"><img className="lazy-load preload-me" src="images/home-support-services.jpg" data-src="images/home-support-services.jpg" data-srcset="images/home-support-services.jpg 500w, images/home-support-services.jpg 800w" width="500" height="500" data-dt-location="images/home-support-services.jpg" alt="" /></a></div> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div id="ultimate-heading-23155b463bf14a225" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-23155b463bf14a225 uvc-4871 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                                  <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                  <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-23155b463bf14a225 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Support Services</h2>
                                  </div>
                                  <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-23155b463bf14a225 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>BluCetus provides Support Services with expert knowledge, tools and technologies that keeps your enterprise up and productive while allowing you to focus on business growth and stay competitive. Support Services cover proactive, reactive, onsite and remote support for Avaya UC & CC Solutions.</div>
                                </div><a href="service-support-services.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1512326560485">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="shortcode-single-image-wrap alignnone  vc_custom_1512321222315">
                                  <div className="shortcode-single-image">
                                      {/* <!-- <div className="fancy-media-wrap" style="border-radius:500px;"><a href="service-training-services.html" className="layzr-bg rollover" style="border-radius:500px;"><img className="lazy-load preload-me" src="images/home-training-services.jpeg" data-src="images/home-training-services.jpeg" data-srcset="images/home-training-services.jpeg 500w, images/home-training-services.jpeg 800w" width="500" height="500" data-dt-location="images/home-training-services.jpeg" alt="" /></a></div> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div id="ultimate-heading-11425b463bf14ab03" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-11425b463bf14ab03 uvc-8264 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                                  <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                  <div className="uvc-main-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-11425b463bf14ab03 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Training Services</h2>
                                  </div>
                                  <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-11425b463bf14ab03 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>We at BluCetus are adept in using the latest technologies to customize learning programs that meet customer's unique and specific training requirements. BluCetus is a combination of extensive experience, global presence, in-depth training and strong dedication that helps customers and partners to get trained on wide range of technologies.</div>
                                </div><a href="service-training-services.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vc_row-full-width vc_clearfix"></div>                
              </div>
          </div>
        </div>
    </div>
    {/* <Contact /> */}
    <CommonFooter />
    </>
  )
}
