import React from 'react'
import './BusinessConsulting.scss'
import CommonFooter from '../CommonFooter'
import Contact from '../Contact'

function BusinessConsulting() {
  return (
    <>
    <div id="main" className="home-page sidebar-none business-consulting sidebar-divider-off" style={{paddingTop:"0px", paddingBottom:"0px"}}>
      <div className="main-gradient"></div>
      <div className="wf-wrap">
        <div className="wf-container-main">
          <div id="content" className="content" role="main">
            <div id="about" className="vc_row wpb_row vc_row-fluid vc_custom_1509351079558">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper">
                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                        <div className="vc_column-inner vc_custom_1515176114270">
                          <div className="wpb_wrapper">
                            <div id="ultimate-heading-72065b463bf142a26" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-72065b463bf142a26 uvc-5951 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                              <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-72065b463bf142a26 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                                <h2 style={{fontWeight:"bold"}}>BUSINESS CONSULTING</h2>
                              </div>
                              <div className="uvc-heading-spacer line_with_icon" >
                                <div className="ult-just-icon-wrapper  ">
                                  <div className="align-icon" style={{textAlign:"center"}}>
                                    <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                      <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>                      

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                        <div className="vc_column-inner vc_custom_1509566445127">
                          <div className="wpb_wrapper">
                            <div id="ultimate-heading-82995b463bf142c1a" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-82995b463bf142c1a uvc-2368 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"left"}}>
                              <div className="uvc-heading-spacer no_spacer"></div>
                              <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-82995b463bf142c1a h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                {/* <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>We are FinCetus</h2> */}
                              </div>
                              <div className="uvc-sub-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-82995b463bf142c1a .uvc-sub-heading ' data-responsive-json-new='{"font-size":"","line-height":"desktop:26px;"}' style={{fontWeight:"normal",marginBottom:"20px",textAlign: "justify"}}>
                                <p>Our Business Consulting solutions are designed to assist individuals who are passionate about starting and managing businesses. Our goal at <strong className='heading-second'>Fin</strong> <strong className='name-secondpart' style={{marginLeft:"-6px"}}>Cetus </strong> is to empower as many new entrepreneurs as possible to create and achieve free market capitalism.  </p>
                                <br></br>
                                <p>Business Start-up consulting services offer comprehensive support for fast-growth businesses and entrepreneurs at every stage of their journey to become global leaders.</p>
                                <br></br>
                                <p>Our team of expert consultants provides valuable advice and guidance to organizations to help them improve their operations, achieve their goals, and overcome complex business challenges.</p>
                              </div>
                            </div>
                            {/* <a href="about.html" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1516622183883" id="default-btn-a54cb064c48eb3b7a7173602fad8c835" style={{width:"180px"}}><span>More about us</span></a> */}
                          </div>
                        </div>
                      </div>

                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-6">
                        <div className="vc_column-inner vc_custom_1509566455479">
                          <div className="wpb_wrapper">
                            <div className="shortcode-single-image-wrap alignnone  vc_custom_1512311389137">
                              <div className="shortcode-single-image">
                                <div className="fancy-media-wrap">
                                  <div className="layzr-bg1 rollover-video"><img className="lazy-load1 preload-me1" src={require("../../images/Business-consulting.jpg")} data-src={require("../../images/Business-consulting.jpg")} data-srcset= "./assets/images/Business-consulting.jpg 1000w, ./assets/images/Business-consulting.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/Business-consulting.jpg")} alt="" />
                                    {/* <img src={require('../images/fin-metting-1.jpg')} /> */}
                                    {/* <!-- <a className="video-icon dt-pswp-item pswp-video" href="https://www.youtube.com/watch?v=YEi_gUElWzA1111" title="" data-dt-img-description=""></a> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>                      
                  </div>
                </div>
              </div>
            </div>            

            <div className="upb_color" data-bg-override="ex-full" data-bg-color="#f7f7f7" data-fadeout="" data-fadeout-percentage="30" data-parallax-content="" data-parallax-content-sense="30" data-row-effect-mobile-disable="true" data-img-parallax-mobile-disable="true" data-rtl="false" data-custom-vc-row="" data-vc="5.4.7" data-is_old_vc="" data-theme-support="" data-overlay="false" data-overlay-color="" data-overlay-pattern="" data-overlay-pattern-opacity="" data-overlay-pattern-size=""></div>

              

              
              

              <div className="upb_grad" data-grad="background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0C5D9A), color-stop(100%, #3DB4EF));background: -moz-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: -webkit-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: -o-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: -ms-linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);background: linear-gradient(left,#0C5D9A 0%,#3DB4EF 100%);" data-bg-override="ex-full" data-upb-overlay-color="" data-upb-bg-animation="" data-fadeout="" data-fadeout-percentage="30" data-parallax-content="" data-parallax-content-sense="30" data-row-effect-mobile-disable="true" data-img-parallax-mobile-disable="true" data-rtl="false" data-custom-vc-row="" data-vc="5.4.7" data-is_old_vc="" data-theme-support="" data-overlay="false" data-overlay-color="" data-overlay-pattern="" data-overlay-pattern-opacity="" data-overlay-pattern-size=""></div>
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1512322712693 vc_row-o-equal-height vc_row-flex" style={{display: "block"}}>
                  <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div id="ultimate-heading-30545b463bf146ed1" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-30545b463bf146ed1 uvc-4776 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                          <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-30545b463bf146ed1 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                            <h2 style={{fontWeight:"bold"}}>Our Services</h2>
                          </div>
                          <div className="uvc-heading-spacer line_with_icon" style={{marginBottom:"30px",height:"18px"}}>
                            <div className="ult-just-icon-wrapper  ">
                              <div className="align-icon" style={{textAlign:"center"}}>
                                <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                  <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="uvc-sub-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-30545b463bf146ed1 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:22px;","line-height":"desktop:34px;"}' style={{fontWeight:"normal",marginBottom:"60px",display: "none"}}>BluCetus services are grouped into six categories: Business Consulting, Technology Consulting, Management Consulting, Professional Services, Support Services and Training Services.</div>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-12 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>BUSINESS CONSULTING</h2>
                                </div>
                                {/* <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div> */}
                              </div>
                              {/* <a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a> */}
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a cust-href="/financial-analysis" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../../images/business-consultings.jpg")} data-src={require("../../images/business-consultings.jpg")} data-srcset="../images/business-consultings.jpg 1000w, ../images/business-consultings.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/business-consultings.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-12 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>SALES</h2>
                                </div>
                                {/* <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div> */}
                              </div>
                              {/* <a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a> */}
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/sales" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../../images/business-sales.jpg")} data-src={require("../../images/business-sales.jpg")} data-srcset="../images/business-sales.jpg 1000w, ../images/business-sales.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/business-sales.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-12 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        
                        
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>MARKETING</h2>
                                </div>
                                {/* <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div> */}
                              </div>
                              {/* <a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a> */}
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/marketing" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../../images/business-marketing.jpg")} data-src={require("../../images/business-marketing.jpg")} data-srcset="../images/business-marketing.jpg 1000w, ../images/business-marketing.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/business-marketing.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-12 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>FINANCIALS</h2>
                                </div>
                                {/* <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div> */}
                              </div>
                              {/* <a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a> */}
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/financials" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../../images/business-financial.jpg")} data-src={require("../../images/business-financial.jpg")} data-srcset="../images/business-financial.jpg 1000w, ../images/business-financial.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/business-financial.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-12 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>DELIVERY</h2>
                                </div>
                                {/* <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div> */}
                              </div>
                              {/* <a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a> */}
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/delivery" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../../images/business-delivery.jpg")} data-src={require("../../images/business-delivery.jpg")} data-srcset="../images/business-delivery.jpg 1000w, ../images/business-delivery.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/business-delivery.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-12 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"20px"}}>SUPPORT</h2>
                                </div>
                                {/* <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div> */}
                              </div>
                              {/* <a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a> */}
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/support" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../../images/business-support.jpg")} data-src={require("../../images/business-support.jpg")} data-srcset="../images/business-support.jpg 1000w, ../images/business-support.jpg 1200w" width="1000" height="600" data-dt-location={require("../../images/business-support.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="vc_row-full-width vc_clearfix"></div>                
              </div>
          </div>
        </div>
    </div>
    {/* <Contact /> */}
    <CommonFooter />
    </>
  )
}

export default BusinessConsulting