import React from 'react'
import Contact from './Contact'
import CommonFooter from './CommonFooter'
import './Service.scss'

function Service() {
  return (
    <>
    <div id="main" className="home-page service-tab sidebar-none sidebar-divider-off" style={{paddingTop:"0px", paddingBottom:"0px"}}>
      <div className="main-gradient"></div>
      <div className="wf-wrap">
        <div className="wf-container-main">
          <div id="content" className="content" role="main">
            

            <div className="upb_color" data-bg-override="ex-full" data-bg-color="#f7f7f7" data-fadeout="" data-fadeout-percentage="30" data-parallax-content="" data-parallax-content-sense="30" data-row-effect-mobile-disable="true" data-img-parallax-mobile-disable="true" data-rtl="false" data-custom-vc-row="" data-vc="5.4.7" data-is_old_vc="" data-theme-support="" data-overlay="false" data-overlay-color="" data-overlay-pattern="" data-overlay-pattern-opacity="" data-overlay-pattern-size=""></div>

              <div className="home-page-our-solutions vc_row wpb_row vc_row-fluid vc_custom_1512322712693 vc_row-o-equal-height vc_row-flex home-page-service">
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div id="ultimate-heading-30545b463bf146ed1" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-30545b463bf146ed1 uvc-4776 accent-border-color accent-icon-color" data-hspacer="line_with_icon" data-hline_width="150" data-hicon_type="selector" data-hborder_style="solid" data-hborder_height="3" data-hborder_color="rgb(255,0,0)" data-icon_width="18" data-hfixer="10" data-halign="center" style={{textAlign:"center"}}>
                        <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-30545b463bf146ed1 h2' data-responsive-json-new='{"font-size":"desktop:40px;mobile_landscape:30px;","line-height":"desktop:50px;mobile_landscape:40px;"}'>
                          <h2 style={{fontWeight:"bold"}}>Core Offerings</h2>
                        </div>
                        <div className="uvc-heading-spacer line_with_icon" style={{marginBottom:"30px",height:"18px"}}>
                          <div className="ult-just-icon-wrapper  ">
                            <div className="align-icon" style={{textAlign:"center"}}>
                              <div className="aio-icon none " style={{fontSize:"18px",display:"inline-block"}}>
                                <i className="icomoon-font-awesome-14x14-chevron-down"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="uvc-sub-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-30545b463bf146ed1 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:22px;","line-height":"desktop:34px;"}' style={{fontWeight:"normal",marginBottom:"60px",display: "none"}}>BluCetus solutions are grouped into three categories: Unified Communication, Contact Center and Custom Applications development.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-4 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326537201">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512326501648">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/trainings" className="layzr-bg rollover">
                                    {/* <img className="lazy-load preload-me" src="images/fin-training.jpg" data-src="images/fin-training.jpg" data-srcset="images/fin-training.jpg 500w, images/fin-training.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/fin-training.jpg")}     data-src={require("../images/fin-training.jpg")}     data-srcset="images/fin-gold-selver.jpg 1000w, images/fin-gold-selver.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-training.jpg")} alt="" />
                                    </a>
                                    </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-67115b463bf147b28" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-67115b463bf147b28 uvc-5000 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>        
                                  <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Trainings</h2>
                                </div>
                                <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-67115b463bf147b28 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>FinCetus offers customized training on macroeconomics, monetary systems, money management, asset classes, and investing.</div><br/>
                              </div><a href="/trainings" className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_column vc_column_container vc_col-sm-4 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326540958">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320579119">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap" style={{borderRadius:"500px"}}><a href="/financial-consulting" className="layzr-bg rollover">
                                    {/* <img className="lazy-load1 preload-me1" src="images/fin-consulting.jpg" data-src="images/fin-consulting.jpg" data-srcset="images/fin-consulting.jpg 500w, images/fin-consulting.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/fin-consulting.jpg")} data-src={require("../images/fin-consulting.jpg")} data-srcset="images/fin-consulting.jpg 1000w, images/fin-consulting.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-consulting.jpg")} alt="" /> </a>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-70525b463bf148518" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-70525b463bf148518 uvc-973 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                    <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Consulting</h2>
                                </div>
                                <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-70525b463bf148518 .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>The solution provided by FinCetus Consulting aims to solve the financial management issues encountered by individuals, business owners, and investors.</div>
                              </div><a href='/financial-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpb_column vc_column_container vc_col-sm-4 our-solutions-panel">
                  <div className="vc_column-inner vc_custom_1512326544626">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512320837278">
                                <div className="shortcode-single-image">
                                  <div className="fancy-media-wrap"><a href="/preciousmetals" className="layzr-bg rollover">
                                    <img className="lazy-load1 preload-me1 gold-tree-img" src={require("../images/fin-Precious-metals.jpg")} data-src={require("../images/fin-Precious-metals.jpg")} data-srcset="images/fin-Precious-metals.jpg 1000w, images/fin-Precious-metals.jpg 1200w" width="1000" height="600" data-dt-location={require("../images/fin-Precious-metals.jpg")} alt="" /> </a>
                                    
                                    {/* <img className="lazy-load preload-me" src="images/fin-Precious-metals.jpg" data-src="images/fin-Precious-metals.jpg" data-srcset="images/fin-Precious-metals.jpg 500w, images/fin-Precious-metals.jpg 800w" width="500" height="500" data-dt-location="images/" alt="" /></a> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                              <div id="ultimate-heading-145b463bf148e9d" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-145b463bf148e9d uvc-6969 " data-hspacer="no_spacer" data-halign="left" style={{textAlign:"center"}}>
                                <div className="uvc-heading-spacer no_spacer" cust-style="top"></div>
                                <div className="uvc-main-heading ult-responsive" data-ultimate-target='.uvc-heading.ultimate-heading-145b463bf148e9d h2' data-responsive-json-new='{"font-size":"desktop:24px;","line-height":"desktop:34px;"}'>
                                  <h2 style={{fontWeight:"bold",marginBottom:"10px"}}>Precious Metals</h2>
                                </div>
                                <div className="uvc-sub-heading ult-responsive home-sub-heading" data-ultimate-target='.uvc-heading.ultimate-heading-145b463bf148e9d .uvc-sub-heading ' data-responsive-json-new='{"font-size":"desktop:16px;","line-height":"desktop:28px;"}' style={{fontWeight:"normal"}}>Precious metals serve as money, commodities, investments, and wealth insurance, providing greater financial stability.</div><br/>
                            </div>
                            <a href='/business-consulting' className="default-btn-shortcode dt-btn dt-btn-m btn-inline-left  vc_custom_1512320334032" id="default-btn-e59d1573212f1ec75e9f61e3f0dca4b8"><span>View details</span></a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           

              <br />
              

              
              </div>
          </div>
        </div>
    </div>
    {/* <Contact /> */}
    <CommonFooter />
    </>
  )
}

export default Service