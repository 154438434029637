import React from 'react'

const CommonFooter = () => {
  return (
    <div className='common-footer'>
      <img src={require("../images/normal-footer.jpg")} width="100%" />  
    </div>
  )
}

export default CommonFooter;